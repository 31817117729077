import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface NotificationData {
  title?: string;
  text?: string;
  isError?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  private _notificationData$ = new BehaviorSubject<NotificationData | null>(null);
  private _timeoutId: ReturnType<typeof setTimeout> | null = null;


  private readonly _notificationLifeTime = 5000;

  public getNotification(): Observable<NotificationData | null> {
    return this._notificationData$.asObservable();
  }

  public openNotification(data: NotificationData): void {
    this._clearTimeout();
    this._notificationData$.next(data);
    this._timeoutId = setTimeout(() => this.hideNotification(), this._notificationLifeTime);
  }

  public hideNotification(): void {
    this._notificationData$.next(null);
  }

  private _clearTimeout(): void {
    this._timeoutId && clearTimeout(this._timeoutId);
    this._timeoutId = null;
  }


}
