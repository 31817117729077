import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showLoading(): void {
    this.loading$.next(true);
  }

  public hideLoading(): void {
    this.loading$.next(false);
  }

}
